import React from "react"
import { Page } from "../../../components/elements"
import { PageCenter } from "../../../components/elements/wrappers"
import { GeneralContactForm } from "../../../components/forms"
import { Col, Row } from "react-bootstrap"
import { DefaultHeader } from "../../../components/elements/Headers.jsx"
import {
    LoadScript,
    GoogleMap,
    InfoWindow,
    Marker,
} from "@react-google-maps/api"
import { Helmet } from "react-helmet"

const containerStyle = {
    width: "100%",
    height: "100%",
}

const options = {
    disableDefaultUI: true,
    mapTypeId: "satellite",
}

export default function ContactPage() {
    return (
        <Page
            title="Contact EGI"
            description="Do you have a question, inquiry or feedback for us? Fill out the form below and we will respond as soon as possible."
        >
            <PageCenter bg="white">
                <DefaultHeader
                    title="Contact Us"
                    subtitle="Do you have a question, inquiry or feedback for us? Fill out the form below and we will respond as soon as possible."
                    type="small"
                />
                <Row className="border-bottom pb-5">
                    <Col>
                        <GeneralContactForm />
                    </Col>
                    <Col>
                        <Helmet>
                            <style type="text/css">
                                {`
                            .gm-style * {
                                outline: none !important;
                            }
                            `}
                            </style>
                        </Helmet>
                        <LoadScript googleMapsApiKey="AIzaSyDmwj0q1-g-xjHQ3dj5Kv6-iwrLpJgyyII">
                            <GoogleMap
                                options={options}
                                mapContainerStyle={containerStyle}
                                center={{
                                    lat: 40.7589,
                                    lng: -111.82785,
                                }}
                                zoom={19}
                            >
                                <Marker
                                    position={{
                                        lat: 40.7587,
                                        lng: -111.8278,
                                    }}
                                >
                                    <InfoWindow
                                        position={{
                                            lat: 40.7587,
                                            lng: -111.8278,
                                        }}
                                    >
                                        <div>
                                            <h5>EGI Main Offices and Labs</h5>
                                            <p style={{ lineHeight: "20px" }}>
                                                Energy & Geoscience Institute
                                                423
                                                <br />
                                                Wakara Way, Suite 300 Salt Lake
                                                <br />
                                                City, UT 84108 801-581-5126
                                            </p>
                                            <a href="https://www.google.com/maps/dir//Energy+%26+Geoscience+Institute,+423+Wakara+Way,+Salt+Lake+City,+UT+84108/@40.7585708,-111.8976992,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x87525fc3bb72ecc3:0x3491fa2357245c73!2m2!1d-111.8276593!2d40.758592">
                                                <b>Get Directions</b>
                                            </a>
                                        </div>
                                    </InfoWindow>
                                </Marker>
                            </GoogleMap>
                        </LoadScript>
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }} className="mt-5">
                    <div className="p-2 m-3">
                        <h5>Hours of Operation</h5>
                        <p>
                            8:00 AM to 5:00 PM <br />
                            Monday through Friday
                        </p>
                    </div>
                    <div className="p-2 m-3">
                        <h5>Phone Numbers</h5>
                        <p>
                            (801) 581-5126 <br />
                            (801) 585-3540
                        </p>
                    </div>
                    <div className="p-2 m-3">
                        <h5>Mailing Address</h5>
                        <p>
                            Energy & Geoscience Institute <br />
                            423 Wakara Way, Suite 300 <br />
                            Salt Lake City, Utah 84108
                        </p>
                    </div>
                </Row>
            </PageCenter>
        </Page>
    )
}
